<template>
	<span>
		<span @click="onShowModal">
			<slot>
				<div v-if="value.length === 0" class="ui-placeholder" type="primary">{{placeholder || '请选择资产数据'}}</div>
				<div v-else class="ui-placeholder" style="color: rgba(0, 0, 0, 0.85);">
					<slot name="value">
						{{ value || '--' }}
					</slot>
				</div>
			</slot>
		</span>
		<a-modal v-model:visible="visible" title="选择资产" width="810px" @cancel="onCancel" @ok="onOK">
			<a-spin :spinning="loading">
				<a-form ref="form" name="form" :model="formState" @finish="onSearch">
					<a-row>
						<a-form-item label="资产编号" class="ui-form__item">
							<a-row>
								<a-col :span="11">
									<a-input v-model:value="formState.startCode" placeholder="资产编号"></a-input>
								</a-col>
								<a-col :span="2" class="ui-form__middle">到</a-col>
								<a-col :span="11">
									<a-input v-model:value="formState.endCode" placeholder="资产编号"></a-input>
								</a-col>
							</a-row>
						</a-form-item>
						
						<a-form-item label="资产名称" name="name" class="ui-form__item">
							<a-input v-model:value="formState.name" placeholder="请输入资产名称"></a-input>
						</a-form-item>
					</a-row>
					
					<a-row>
						<a-col :span="18" style="padding-top: 10px;">
							<span>已选择{{ selectedRowKeys.length }} 位</span>
							<a-button type="link" size="small" @click="onCleck">清空选择</a-button>
						</a-col>
						<a-col :span="6" style="text-align: right;">
							<a-button type="primary" html-type="submit">搜索</a-button>
							<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>
				
				<a-table rowKey="id"
					style="margin-top: 10px;" 
					size="small"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" 
					:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
				>
				</a-table>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import { getAssetsList } from '@/service/modules/property.js';
	export default {
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: {
				type: String,
				default: 'radio'
			},
			pageType: {
				type: Number,
				default: 0
			},
			placeholder: {
				type: String, 
				default: ''
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				selectSubmitData: [],
				list: [],
				columns: [{
					title: '资产分类',
					align: 'center',
					dataIndex: "classifyName"
				}, {
					title: '资产编号',
					align: 'center',
					key: 'code',
					dataIndex: "code"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "name"
				},{
					title: '使用人',
					align: 'center',
					dataIndex: "user"
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			onShowModal() {
				this.visible = true;
				this.$nextTick(() => {
					this.reset();
				})
			},
			reset() {
				this.formState = {};
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState))
				this.loading = true;
				this.getData();
			},
			async getData() {
				try {
					let ret = await getAssetsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onCleck() {
				this.selectedListData = [];
				this.selectedRowKeys = [];
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onCancel() {
			    this.visible = false;
			},
			onOK() {
				this.$emit('update:value', this.selectedRowKeys);
				this.$emit('change', this.selectedListData);
				this.selectSubmitData = JSON.parse(JSON.stringify(this.selectedListData));
				this.onCancel();
			}
		}
	}
</script>

<style scoped>
	.ui-placeholder {
		width: 100%;
		padding: 4px 11px;
		color: #d9d9d9;
		font-size: 14px;
		line-height: 1.5715;
		border-radius: 2px;
		border: 1px solid #d9d9d9;
		cursor: pointer;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.a-form-item {
		margin-right: 20px;
	}
</style>
